import revive_payload_client_V7caUKPT2c from "/Users/whitney/Work/lindercube/nuxt3-monorepo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_less@4.2.0_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dAxCGEO4WQ from "/Users/whitney/Work/lindercube/nuxt3-monorepo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_less@4.2.0_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UQXVF5OMsR from "/Users/whitney/Work/lindercube/nuxt3-monorepo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_less@4.2.0_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_TKk9vupdR2 from "/Users/whitney/Work/lindercube/nuxt3-monorepo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_less@4.2.0_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_iFCxVURNqx from "/Users/whitney/Work/lindercube/nuxt3-monorepo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_less@4.2.0_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_tS57P5gbk1 from "/Users/whitney/Work/lindercube/nuxt3-monorepo/node_modules/.pnpm/@pinia+nuxt@0.5.1_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/whitney/Work/lindercube/nuxt3-monorepo/apps/tyhub/.nuxt/components.plugin.mjs";
import prefetch_client_vikPAb7yQP from "/Users/whitney/Work/lindercube/nuxt3-monorepo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_less@4.2.0_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/Users/whitney/Work/lindercube/nuxt3-monorepo/apps/tyhub/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/Users/whitney/Work/lindercube/nuxt3-monorepo/apps/tyhub/.nuxt/element-plus-injection.plugin.mjs";
import unocss_MzCDxu9LMj from "/Users/whitney/Work/lindercube/nuxt3-monorepo/apps/tyhub/.nuxt/unocss.mjs";
import chunk_reload_client_fDmkJxt5mz from "/Users/whitney/Work/lindercube/nuxt3-monorepo/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_floating-vue@5.2.2_less@4.2.0_unocss@0.59.2_vite@5.2.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_V7caUKPT2c,
  unhead_dAxCGEO4WQ,
  router_UQXVF5OMsR,
  payload_client_TKk9vupdR2,
  check_outdated_build_client_iFCxVURNqx,
  plugin_vue3_tS57P5gbk1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vikPAb7yQP,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  unocss_MzCDxu9LMj,
  chunk_reload_client_fDmkJxt5mz
]