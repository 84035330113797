import { default as indexZpgvxVU30XMeta } from "/Users/whitney/Work/lindercube/nuxt3-monorepo/apps/tyhub/pages/index.vue?macro=true";
export default [
  {
    name: indexZpgvxVU30XMeta?.name ?? "index",
    path: indexZpgvxVU30XMeta?.path ?? "/",
    meta: indexZpgvxVU30XMeta || {},
    alias: indexZpgvxVU30XMeta?.alias || [],
    redirect: indexZpgvxVU30XMeta?.redirect,
    component: () => import("/Users/whitney/Work/lindercube/nuxt3-monorepo/apps/tyhub/pages/index.vue").then(m => m.default || m)
  }
]