export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"tyhub无需注册即可传输文件，上传下载不限速。可以无限制传视频、传音频、传图片、跨安装包、传大文件等。对于需要快速跨设备跨终端传输或者分享内容的用户非常有用。最受用户喜爱的效率工具之一，快来体验吧！"},{"name":"keywords","content":"快传,互传,不限速,上传工具,安全快速发送文件,跨设备,共享文件,传输文件,快速传输"}],"link":[{}],"style":[],"script":[{"type":"text/javascript","innerHTML":"var _hmt = _hmt || [];\n          (function() {\n            var hm = document.createElement(\"script\");\n            hm.src = \"https://hm.baidu.com/hm.js?91e7b683ed0970ff7f3e66ca2eac66a8\";\n            var s = document.getElementsByTagName(\"script\")[0];\n            s.parentNode.insertBefore(hm, s);\n          })();"}],"noscript":[],"title":"tyhub | 跨设备文件传输共享工具","htmlAttrs":{"lang":"zh-CN"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false